<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <em *ngIf="!form" class="las la-spinner la-spin la-5x"></em>
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="save(form.value)">
    <div class="field">
      <label class="label">Titel<span>*</span></label>
      <div class="control">
        <input
          class="input"
          [ngClass]="{
            'is-danger':
              f['title'].invalid && (f['title'].dirty || f['title'].touched)
          }"
          type="text"
          name="title"
          placeholder="Titel"
          formControlName="title"
        />
      </div>
      <div
        class="errors"
        *ngIf="f['title'].invalid && (f['title'].dirty || f['title'].touched)"
      >
        <p class="help is-danger" *ngIf="f['title'].errors?.['required']">
          Titel is verplicht!
        </p>
      </div>
    </div>

    <div class="field">
      <label class="label">Beschrijving</label>
      <div class="control">
        <input
          class="input"
          [ngClass]="{
            'is-danger':
              f['description'].invalid &&
              (f['description'].dirty || f['description'].touched)
          }"
          type="text"
          name="description"
          placeholder="Beschrijving"
          formControlName="description"
        />
      </div>
      <div
        class="errors"
        *ngIf="
          f['description'].invalid &&
          (f['description'].dirty || f['description'].touched)
        "
      >
        <p
          class="help is-danger"
          *ngIf="f['description'].errors?.['maxLength']"
        >
          De beschrijving mag maar max 1000 karakters bevatten!
        </p>
      </div>
    </div>

    <div class="field">
      <label class="label">Bijlage kiezen<span>*</span></label>
      <div class="control">
        <input
          class="input"
          [ngClass]="{
            'is-danger':
              f['description'].invalid &&
              (f['description'].dirty || f['description'].touched)
          }"
          type="file"
          name="file"
          accept="application/pdf"
          placeholder="Bestand"
          (change)="onFileChange($event)"
        />
      </div>

      <div
        class="errors"
        *ngIf="f['file'].invalid && (f['file'].dirty || f['file'].touched)"
      >
        <p class="help is-danger" *ngIf="f['file'].errors?.['required']">
          Een bestand is verplicht!
        </p>
      </div>
    </div>

    <p *ngIf="error" class="help is-danger">{{ error }}!</p>
  </form>
</div>
<div mat-dialog-actions style="justify-content: space-between">
  <button class="button is-text" (click)="onNoClick()">Annuleer</button>
  <button
    *ngIf="form && !readonly"
    class="button is-info"
    (click)="save(form.value)"
    [disabled]="form.invalid || loading"
  >
    <span>Opslaan </span>
    <span class="icon" *ngIf="loading">
      <i class="las la-spinner la-spin"></i>
    </span>
  </button>
</div>
