import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BudgetYear } from '../models/budgetYear.interface';

@Injectable({ providedIn: 'root' })
export class BudgetYearService {
  constructor(private http: HttpClient) {}

  getBudgetYears() {
    return this.http.get<any>(environment.apiUrl + '/api/budgetyears');
  }

  getBudgetYearById(id: number) {
    return this.http.get<any>(environment.apiUrl + `/api/budgetyears/${id}`);
  }

  createBudgetYear(budgetYear: BudgetYear) {
    return this.http.post<any>(
      environment.apiUrl + '/api/budgetyears',
      budgetYear
    );
  }

  updateBudgetYear(id: number, budgetYear: BudgetYear) {
    return this.http.put<any>(
      environment.apiUrl + `/api/budgetyears/${id}`,
      budgetYear
    );
  }

  archiveBudgetYear(isArchive: boolean, id: number) {
    return this.http.put<any>(
      environment.apiUrl + `/api/budgetyears/archive/${id}`,
      {
        is_archived: isArchive,
      }
    );
  }
}
