import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./modules/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'articles',
    loadChildren: () =>
      import('./modules/articles/articles.module').then(
        (m) => m.ArticlesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'contracts',
    loadChildren: () =>
      import('./modules/contracts/contracts.module').then(
        (m) => m.ContractsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'festivals',
    loadChildren: () =>
      import('./modules/festivals/festivals.module').then(
        (m) => m.FestivalsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'suppliers',
    loadChildren: () =>
      import('./modules/suppliers/suppliers.module').then(
        (m) => m.SuppliersModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'suppliers-check',
    loadChildren: () =>
      import('./modules/suppliers-check/suppliers-check.module').then(
        (m) => m.SuppliersCheckModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
