import { CurrencyPipe } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Budget } from 'src/app/models/budget.interface';
import { BudgetPost } from 'src/app/models/budgetPost.interface';
import { Order } from 'src/app/models/order.interface';
import {
  ArchiveBudgetPostById,
  GetBudgetPosts,
} from 'src/app/store/actions/budget-post.action';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { EditBudgetPostComponent } from '../edit-budgetpost/edit-budgetpost.component';

@Component({
  selector: 'app-budgetposts-table',
  templateUrl: './budgetposts-table.component.html',
  styleUrls: ['./budgetposts-table.component.scss'],
})
export class BudgetpostsTableComponent implements OnInit, OnChanges {
  @Input() budgetPosts: BudgetPost[] = [];
  sortedData: BudgetPost[] = [];

  data: BudgetPost[] = [];

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit() {
    this.data = [...this.budgetPosts];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sortedData = this.budgetPosts.slice();
  }

  sortData(sort: Sort) {
    const data = this.sortedData.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
    } else {
      this.sortedData = data.sort((a, b) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  openDialog(id: number | null = null) {
    const dialogRef = this.dialog.open(EditBudgetPostComponent, {
      width: '500px',
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.store.dispatch(new GetBudgetPosts());
    });
  }

  archive(id: number, isArchived: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Weet u zeker dat u deze budgetpost wilt ${
          !isArchived ? 'heractiveren' : 'archiveren'
        }?`,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.store
          .dispatch(new ArchiveBudgetPostById(isArchived, id))
          .subscribe(
            (data) => {
              this.toastr.success(
                `Het budgetjaar is ${
                  isArchived ? 'geactiveerd' : 'gearchiveerd'
                }!`
              );
            },
            (error) => {
              this.toastr.error(error.error, 'Er is iets misgegaan!');
            }
          );
      }
    });
  }

  calculateBudget(budgets: Budget[]) {
    if (budgets.length > 0) {
      return this.currencyPipe.transform(budgets[0].budget, 'EUR');
    } else {
      return '-';
    }
  }

  calculateTotalSpending(orders: Order[]): number {
    let total = 0;
    if (orders.length > 0) {
      orders.forEach((order: Order) => {
        total += order.products_price;
      });
      return total;
    } else {
      return 0;
    }
  }

  calculateMaxSpending(budgets: Budget[]): number {
    if (budgets.length > 0) {
      return budgets[0].budget;
    } else {
      return 0;
    }
  }

  getLimitSpending(spend: number, max: number): string {
    if (spend > max) {
      return 'is-danger';
    }
    return '';
  }
}
