import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Festival } from 'src/app/models/festival.interface';
import { FestivalService } from 'src/app/services/festival.service';
import {
  CreateFestival,
  GetFestivals,
  UpdateFestival,
} from 'src/app/store/actions/festival.action';

@Component({
  selector: 'app-festival-upsert',
  templateUrl: './festival-upsert.component.html',
  styleUrls: ['./festival-upsert.component.scss'],
})
export class FestivalUpsertComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  error: string = '';
  title: string = 'Festival';
  readonly: boolean = false;

  constructor(
    private festivalService: FestivalService,
    private store: Store,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FestivalUpsertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.getFestival(this.data.id);
    } else {
      this.initForm(null);
    }

    this.readonly = this.data.readonly;
  }

  getFestival(id: number): void {
    this.festivalService.getFestivalById(id).subscribe((data) => {
      this.initForm(data);
    });
  }

  initForm(festival: Festival | null): void {
    this.form = this.fb.group({
      title: [festival?.title || '', Validators.required],
      description: [festival?.description || '', Validators.maxLength(1000)],
    });

    this.readonly && this.form.disable();
  }

  save(festival: any): void {
    this.loading = true;

    const req = {
      title: festival.title,
      description: festival.description,
    };

    if (this.data.id) {
      this.store.dispatch(new UpdateFestival(req, this.data.id)).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Festival is gewijzigd');
          this.dialogRef.close();

          this.store.dispatch(new GetFestivals());
        },
        (error) => {
          this.toastr.error(error.error, 'Er is iets misgegaan!');
          this.error = error.error;
          this.loading = false;
        }
      );
    } else {
      this.store.dispatch(new CreateFestival(req)).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Festival is toegevoegd');
          this.dialogRef.close();

          this.store.dispatch(new GetFestivals());
        },
        (error) => {
          this.toastr.error(error.error, 'Er is iets misgegaan!');
          this.error = error.error;
          this.loading = false;
        }
      );
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get f() {
    return this.form.controls;
  }
}
