import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(sessionStorage['currentUser'] || null)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  loginUser(user: any) {
    return this.http.post<any>(environment.apiUrl + '/api/login', user).pipe(
      map((data) => {
        // login successful if there's a token
        if (data && data.access_token && data.user.is_active) {
          // store user details and token in local storage to keep user logged in
          data.user.access_token = data.access_token;
          sessionStorage.setItem('currentUser', JSON.stringify(data.user));
          this.currentUserSubject.next(data.user);
        }
        return data;
      })
    );
  }

  registerUser(user: any) {
    return this.http.post<any>(environment.apiUrl + '/api/register', user).pipe(
      map((data) => {
        // Register successful if there's a token
        if (data && data.access_token) {
          // store user details and token in local storage to keep user logged in
          data.user.access_token = data.access_token;
          sessionStorage.setItem('currentUser', JSON.stringify(data.user));
          this.currentUserSubject.next(data);
        }

        return data;
      })
    );
  }

  requestNewPassword(email: any) {
    return this.http.post<any>(
      environment.apiUrl + '/api/password/email',
      email
    );
  }

  resetPassword(credentials: any) {
    return this.http.post<any>(
      environment.apiUrl + '/api/password/reset',
      credentials
    );
  }

  logout() {
    this.http.post<any>(environment.apiUrl + '/api/logout', null).subscribe();
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  isAdmin(): boolean {
    return this.currentUserValue?.is_admin == 1;
  }
}
