import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Festival } from 'src/app/models/festival.interface';
import { FestivalState } from 'src/app/store/states/festival.state';
import { FestivalUpsertComponent } from '../../festivals/festival-upsert/festival-upsert.component';
import { GetFestivals } from 'src/app/store/actions/festival.action';

@Component({
  selector: 'app-festival-selector',
  templateUrl: './festival-selector.component.html',
  styleUrls: ['./festival-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FestivalSelectorComponent),
      multi: true,
    },
  ],
})
export class FestivalSelectorComponent implements ControlValueAccessor {
  dataSource: Festival[] = [];
  input = '';
  disabled = false;
  @Input()
  label: boolean = true;
  @Input()
  required: boolean = false;

  @Select(FestivalState.festivals)
  festivals$: Observable<Festival[]>;

  constructor(public dialog: MatDialog, private store: Store) {}

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: string) {
    this.input = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  isDisabled(): boolean {
    return this.disabled;
  }

  openFestivalUpsertDialog(): void {
    this.dialog.open(FestivalUpsertComponent, {
      width: '500px',
      data: { id: null },
    });
  }
}
