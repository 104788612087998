import { SupplierForm } from 'src/app/models/supplier.interface';

export class GetSuppliers {
  static readonly type = '[Suppliers] Get suppliers';
}

export class GetSuppliersToCheck {
  static readonly type = '[Suppliers] Get suppliers to check';
}

export class GetSupplierById {
  static readonly type = '[Suppliers] Get supplier';
  constructor(public id: number) {}
}

export class CreateSupplier {
  static readonly type = '[Suppliers] Create supplier';
  constructor(public payload: SupplierForm) {}
}

export class DeleteSupplier {
  static readonly type = '[Suppliers] Delete supplier';
  constructor(public id: number) {}
}

export class UpdateSupplier {
  static readonly type = '[Suppliers] Update supplier';
  constructor(public payload: SupplierForm, public id: number) {}
}
