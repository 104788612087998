<h1 mat-dialog-title>Bestelbon evalueren</h1>
<div mat-dialog-content>
  <div class="fied">
    <label class="label">Notitie</label>
    <div class="control">
      <textarea class="textarea" placeholder="..." [(ngModel)]="comment"></textarea>
    </div>
  </div>
  <div class="field">
    <p>Let op, bij afkeuren moet heel het goedkeuringsproces volledig opnieuw gebeuren!</p>
  </div>

  <div class="errors" *ngIf="error.length > 0">
    <p class="help is-danger">{{error}}</p>
  </div>
</div>
<div mat-dialog-actions style="justify-content: space-between">
  <button class="button is-text" mat-dialog-close>Annuleren</button>
  <div>
    <button class="button is-danger" style="margin-right: 1rem;" (click)="save(false)">Afkeuren</button>
    <button class="button is-success" (click)='save(true)'>Goedkeuren</button>
  </div>
</div>
