import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { APPROVAL_TYPES } from 'src/app/models/ApprovalType.enum';
import { Order } from 'src/app/models/order.interface';
import { Product } from 'src/app/models/product.interface';
import { OrderService } from 'src/app/services/order.service';
import { DeleteOrderById } from 'src/app/store/actions/order.action';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { LinkedOrdersComponent } from '../linked-orders/linked-orders.component';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
})
export class OrdersTableComponent implements OnInit, OnChanges {
  @Input() orders: Order[];
  sortedData: any[] = [];
  lastStartDate: any;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  statuses: { name: string; value: boolean }[] = [
    { name: 'Open', value: false },
    { name: 'Gearchiveerd', value: true },
  ];

  selectedStatus = false;
  onFilterChange(event: boolean) {
    // TODO: add is archived boolean to model
    this.selectedStatus = event;
    this.sortedData = this.orders
      .slice()
      .filter((order: Order) => order.archived == event);
  }

  constructor(
    private store: Store,
    public dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<LinkedOrdersComponent>,
    private toastr: ToastrService,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.range.valueChanges.subscribe(() => {
      this.filterOrdersByDate();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.sortedData = this.orders
      .filter((order: Order) => order.archived == this.selectedStatus)
      .slice();
  }

  sortData(sort: Sort) {
    const data = this.orders.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
    } else {
      this.sortedData = data.sort((a: any, b: any) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  clearDateRange() {
    this.range.reset();
    this.sortedData = this.orders;
  }

  filterOrdersByDate() {
    let startDate = this.range.get('start')?.value;
    let endDate = this.range.get('end')?.value;

    if (startDate != this.lastStartDate)
    {
      this.lastStartDate = startDate;
      endDate = startDate;
    }
    if(startDate && endDate){
      this.sortedData = this.orders
      .filter((order: Order) => {
        const updatedAt = new Date(order.updated_at);
        return (!startDate || updatedAt >= new Date(startDate)) &&
               (!endDate || updatedAt <= new Date(endDate));
      })

    }
  }

  getTotalCost(products: Product[]): number {
    let cost = 0;
    products.forEach((element) => {
      cost = cost + element.unit_price;
    });

    return cost;
  }

  openDeleteDialog(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Wilt u deze zeker verwijderen?' },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.store.dispatch(new DeleteOrderById(id)).subscribe(
          (data) => {
            this.toastr.success('Bestelbon is verwijderd!');
          },
          (error) => {
            this.toastr.error(error.error, 'Er is iets misgegaan!');
          }
        );
      }
    });
  }

  calculateStatus(statuses: any[]): number {
    let value = 1;
    statuses.forEach((status) => {
      if (status.approved && status.approved === APPROVAL_TYPES.APPROVED) {
        value += 1;
      }
    });
    return value;
  }

  generateOrderPDF(orderId: number) {
    this.orderService
      .generateOrderPDF(orderId)
      .subscribe((blob: Blob): void => {
        const file = new Blob([blob], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      });
  }

  closeRefDialog(): void {
    this.dialogRef.close();
  }
}
