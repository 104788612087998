import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Contract } from 'src/app/models/contract.interface';
import { ContractService } from 'src/app/services/contract.service';
import {
  CreateContract,
  GetContractById,
  GetContracts,
  UpdateContract,
} from '../actions/contract.action';

export class ContractStateModel {
  contracts: Contract[];
  selectedContract: Contract | null;
  isLoading: boolean;
}

@State<ContractStateModel>({
  name: 'contract',
  defaults: {
    contracts: [],
    selectedContract: null,
    isLoading: false,
  },
})
@Injectable()
export class ContractState {
  constructor(private contractService: ContractService) {}

  @Selector()
  static contracts(state: ContractStateModel): Contract[] {
    return state.contracts;
  }

  @Selector()
  static isLoading(state: ContractStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static selectedContract(state: ContractStateModel) {
    return state.selectedContract;
  }

  @Action(GetContracts)
  getContracts(ctx: StateContext<ContractStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.contractService.getContracts().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          contracts: data,
        });
      })
    );
  }

  @Action(GetContractById)
  getContractsById(
    ctx: StateContext<ContractStateModel>,
    action: GetContractById
  ) {
    return this.contractService.getContractById(action.id).pipe(
      tap((data) => {
        ctx.patchState({
          selectedContract: data,
        });
      })
    );
  }

  @Action(CreateContract)
  createContract(
    ctx: StateContext<ContractStateModel>,
    { payload }: CreateContract
  ) {
    return this.contractService.createContract(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          contracts: [...state.contracts, data],
          selectedContract: data,
        });
      })
    );
  }

  @Action(UpdateContract)
  updateContract(
    ctx: StateContext<ContractStateModel>,
    { payload, id }: UpdateContract
  ) {
    return this.contractService.updateContract(payload, id).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          contracts: [...state.contracts, data],
          selectedContract: data,
        });
      })
    );
  }
}
