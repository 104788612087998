import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { BudgetYear } from 'src/app/models/budgetYear.interface';
import { BudgetYearService } from 'src/app/services/budgetyear.service';
import {
  CreateBudgetYear,
  GetBudgetYears,
  GetBudgetYearById,
  UpdateBudgetYear,
  ArchiveBudgetYearById,
} from '../actions/budget-year.action';

export class BudgetYearStateModel {
  budgetYears: BudgetYear[];
  isLoading: boolean;
}

@State<BudgetYearStateModel>({
  name: 'budgetYear',
  defaults: {
    budgetYears: [],
    isLoading: false,
  },
})
@Injectable()
export class BudgetYearState {
  constructor(private budgetYearService: BudgetYearService) {}

  @Selector()
  static budgetYears(state: BudgetYearStateModel) {
    return state.budgetYears;
  }

  @Selector()
  static activeBudgetYears(state: BudgetYearStateModel) {
    return state.budgetYears.filter((by: BudgetYear) => !by.is_archived);
  }

  @Selector()
  static isLoading(state: BudgetYearStateModel): boolean {
    return state.isLoading;
  }

  @Action(GetBudgetYears)
  getBudgetYears(ctx: StateContext<BudgetYearStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    return this.budgetYearService.getBudgetYears().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          budgetYears: data,
        });
      })
    );
  }

  @Action(GetBudgetYearById)
  getBudgetYearsById(
    ctx: StateContext<BudgetYearStateModel>,
    action: GetBudgetYearById
  ) {
    return this.budgetYearService.getBudgetYearById(action.id).pipe(
      tap((data) => {
        const state = ctx.getState();

        ctx.setState({
          ...state,
          budgetYears: data,
        });
      })
    );
  }

  @Action(CreateBudgetYear)
  createBudgetYear(
    ctx: StateContext<BudgetYearStateModel>,
    { payload }: CreateBudgetYear
  ) {
    return this.budgetYearService.createBudgetYear(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          budgetYears: [...state.budgetYears, data],
        });
      })
    );
  }

  @Action(UpdateBudgetYear)
  updateBudgetYear(
    ctx: StateContext<BudgetYearStateModel>,
    { payload, id }: UpdateBudgetYear
  ) {
    return this.budgetYearService.updateBudgetYear(id, payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          budgetYears: [...state.budgetYears, data],
        });
      })
    );
  }

  @Action(ArchiveBudgetYearById)
  archiveBudgetYearById(
    ctx: StateContext<BudgetYearStateModel>,
    { payload, id }: ArchiveBudgetYearById
  ) {
    return this.budgetYearService.archiveBudgetYear(payload, id).pipe(
      tap((data) => {
        ctx.patchState({
          budgetYears: data,
        });
      })
    );
  }
}
