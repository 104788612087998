import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BudgetYearSelectorComponent } from './budget-year-selector/budget-year-selector.component';
import { BudgetpostsTableComponent } from './budgetposts-table/budgetposts-table.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContractSelectorComponent } from './contract-selector/contract-selector.component';
import { EditBudgetPostComponent } from './edit-budgetpost/edit-budgetpost.component';
import { EvaluateOrderModalComponent } from './evaluate-order-modal/evaluate-order-modal.component';
import { EvaluateOrderComponent } from './evaluate-order/evaluate-order.component';
import { FestivalSelectorComponent } from './festival-selector/festival-selector.component';
import { NoDataComponent } from './no-data/no-data.component';
import { OrdersTableComponent } from './orders-table/orders-table.component';
import { RoleSelectorComponent } from './role-selector/role-selector.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { StatusFilterComponent } from './status-filter/status-filter.component';
import { TitleComponent } from './title/title.component';

import { DndModule } from 'ngx-drag-drop';
import { EvaluateInvoiceModalComponent } from './evaluate-invoice-modal/evaluate-invoice-modal.component';
import { EvaluateInvoiceComponent } from './evaluate-invoice/evaluate-invoice.component';
import { SupplierDetailDialogComponent } from './supplier-detail-dialog/supplier-detail-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { LinkedOrdersComponent } from './linked-orders/linked-orders.component';

const SHARED = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MatSortModule,
  MatDialogModule,
  MatTabsModule,
  MatMenuModule,
  MatSelectModule,
  MatTooltipModule,
  NgSelectModule,
  MatAutocompleteModule,
  DragDropModule,
  DndModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
];

const SHARED_COMPONENTS = [
  RoleSelectorComponent,
  EditBudgetPostComponent,
  BudgetYearSelectorComponent,
  ConfirmDialogComponent,
  TitleComponent,
  NoDataComponent,
  StatusBarComponent,
  EvaluateOrderComponent,
  EvaluateOrderModalComponent,
  OrdersTableComponent,
  BudgetpostsTableComponent,
  StatusFilterComponent,
  FestivalSelectorComponent,
  ContractSelectorComponent,
  EvaluateInvoiceComponent,
  EvaluateInvoiceModalComponent,
  SupplierDetailDialogComponent,
  LinkedOrdersComponent
];

@NgModule({
  declarations: [SHARED_COMPONENTS],
  imports: [SHARED],
  exports: [SHARED, SHARED_COMPONENTS],
  providers: [DecimalPipe, CurrencyPipe],
})
export class SharedModule {}
