import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Role } from 'src/app/models/role.interface';
import { RoleService } from 'src/app/services/role.service';
import { Select, Store } from '@ngxs/store';
import { RoleState } from 'src/app/store/states/role.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleSelectorComponent),
      multi: true,
    },
  ],
})
export class RoleSelectorComponent implements ControlValueAccessor {
  dataSource: Role[] = [];
  input = '';
  disabled = false;

  @Select(RoleState.roles)
  roles$: Observable<Role[]>;

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: string) {
    this.input = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  isDisabled(): boolean {
    return this.disabled;
  }
}
