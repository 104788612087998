import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.interface';
import { UserService } from 'src/app/services/user.service';
import {
  ChangeActiveUser,
  CreateUser,
  GetUserById,
  GetUsers,
  UpdateUser,
} from '../actions/user.action';

export class UserStateModel {
  users: User[];
  selectedUser: User | null;
  isLoading: boolean;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    users: [],
    selectedUser: null,
    isLoading: false,
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  static users(state: UserStateModel): User[] {
    return state.users;
  }

  @Selector()
  static isLoading(state: UserStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static selectedUser(state: UserStateModel) {
    return state.selectedUser;
  }

  @Action(GetUsers)
  getUsers(ctx: StateContext<UserStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.userService.getUsers().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          users: data,
        });
      })
    );
  }

  @Action(GetUserById)
  getUsersById(ctx: StateContext<UserStateModel>, action: GetUserById) {
    return this.userService.getUserById(action.id).pipe(
      tap((data) => {
        ctx.patchState({
          selectedUser: data,
        });
      })
    );
  }

  @Action(CreateUser)
  createUser(ctx: StateContext<UserStateModel>, { payload }: CreateUser) {
    return this.userService.createUser(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          users: [...state.users, data],
          selectedUser: data,
        });
      })
    );
  }

  @Action(UpdateUser)
  updateUser(ctx: StateContext<UserStateModel>, { payload, id }: UpdateUser) {
    return this.userService.updateUser(payload, id).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          users: [...state.users, data],
          selectedUser: data,
        });
      })
    );
  }

  @Action(ChangeActiveUser)
  changeActiveUser(
    ctx: StateContext<UserStateModel>,
    { payload, id }: ChangeActiveUser
  ) {
    return this.userService.changeActiveUser(payload, id).pipe(
      tap((data) => {
        ctx.patchState({
          users: data,
          selectedUser: data,
        });
      })
    );
  }
}
