import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Supplier } from 'src/app/models/supplier.interface';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail-dialog.component.html',
  styleUrls: ['./supplier-detail-dialog.component.scss'],
})
export class SupplierDetailDialogComponent implements OnInit {
  loading: boolean;
  supplier: Supplier;

  constructor(
    private supplierService: SupplierService,
    public dialogRef: MatDialogRef<SupplierDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number }
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.supplierService
      .getSupplier(this.data.id)
      .subscribe((data: Supplier) => {
        this.supplier = data;
        this.loading = false;
      });
  }
}
