import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Order } from '../models/order.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get<Order[]>(environment.apiUrl + '/api/orders');
  }

  getOrderById(id: number) {
    return this.http.get<Order>(environment.apiUrl + `/api/orders/${id}`);
  }

  formatData(order: any): FormData {
    const formData = new FormData();

    for (let key in order) {
      if (key === 'files') {
        order.files.forEach((file: any) => {
          formData.append('files[]', file);
        });
      } else if (key === 'products') {
        formData.append('products', JSON.stringify(order[key as keyof Order]));
      } else {
        formData.append(key, order[key as keyof Order]);
      }
    }

    return formData;
  }

  createOrder(order: Order) {
    const formData = this.formatData(order);
    return this.http.post<Order>(environment.apiUrl + '/api/orders', formData);
  }

  updateOrder(id: number, order: any) {
    const formData = this.formatData(order);

    return this.http.post<Order>(
      environment.apiUrl + `/api/orders/update/${id}`,
      formData
    );
  }

  updateOrderFiles(id: number, order: any) {
    const formData = this.formatData(order);

    return this.http.post<Order>(
      environment.apiUrl + `/api/orders/files/${id}`,
      formData
    );
  }

  uploadInvoiceFiles(id: number, order: any) {
    const formData = this.formatData(order);

    return this.http.post<Order>(
      environment.apiUrl + `/api/orders/files/invoice/${id}`,
      formData
    );
  }

  deleteOrder(id: number) {
    return this.http.delete<Order>(environment.apiUrl + `/api/orders/${id}`);
  }

  evaluate(id: number, orderStatus: any) {
    return this.http.put<any>(
      environment.apiUrl + `/api/orders/evaluate/${id}`,
      orderStatus
    );
  }

  evaluateInvoice(id: number, orderStatus: any) {
    return this.http.put<any>(
      environment.apiUrl + `/api/orders/evaluate/invoice/${id}`,
      orderStatus
    );
  }

  generateOrderPDF(id: number) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(environment.apiUrl + `/api/orders/pdf/${id}`, {
      headers: headers,
      responseType: 'blob',
    });
  }

  downloadFile(id: number): Observable<Blob> {
    return this.http.get(environment.apiUrl + `/api/orderfile/${id}`, {
      responseType: 'blob',
    });
  }

  deleteFile(id: number): Observable<Blob> {
    return this.http.delete(environment.apiUrl + `/api/orderfile/${id}`, {
      responseType: 'blob',
    });
  }

  archiveOrder(id: number) {
    return this.http.post<any>(
      environment.apiUrl + `/api/orders/archive/${id}`,
      {}
    );
  }

  sendFiles(id: number) {
    return this.http.get(environment.apiUrl + `/api/orders/send-files/${id}`);
  }

  getLinkedOrders(item_type: string, item_id: number) {
    const params = { item_type, item_id: item_id.toString() };
    return this.http.get<any>(`${environment.apiUrl}/api/orders/linked-orders/get`, { params });
  }
}
