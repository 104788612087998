import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BudgetYear } from 'src/app/models/budgetYear.interface';
import { BudgetYearState } from 'src/app/store/states/budget-year.state';

@Component({
  selector: 'app-budget-year-selector',
  templateUrl: './budget-year-selector.component.html',
  styleUrls: ['./budget-year-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BudgetYearSelectorComponent),
      multi: true,
    },
  ],
})
export class BudgetYearSelectorComponent implements ControlValueAccessor {
  dataSource: BudgetYear[] = [];
  input = '';
  disabled = false;
  @Input()
  label: boolean = true;
  @Input()
  required: boolean = false;

  @Select(BudgetYearState.activeBudgetYears)
  budgetYears$: Observable<BudgetYear[]>;

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: string) {
    this.input = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  isDisabled(): boolean {
    return this.disabled;
  }
}
