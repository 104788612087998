export class GetBudgetPosts {
  static readonly type = '[BudgetPosts] Get budgetposts';
}

export class GetBudgetPostsList {
  static readonly type = '[BudgetPosts] Get budgetpost list';
}

export class GetBudgetPostById {
  static readonly type = '[BudgetPosts] Get budgetpost';
  constructor(public id: number) {}
}

export class CreateBudgetPost {
  static readonly type = '[BudgetPosts] Create budgetpost';
  constructor(public payload: any) {}
}

export class UpdateBudgetPost {
  static readonly type = '[BudgetPosts] Update budgetpost';
  constructor(public payload: any, public id: number) {}
}

export class UpdateBudgets {
  static readonly type = '[BudgetPosts] Update budget';
  constructor(public payload: any, public id: number) {}
}

export class CreateBudget {
  static readonly type = '[BudgetPosts] Create budget';
  constructor(public payload: any, public id: number) {}
}

export class GetBudgetPostBudgetYearById {
  static readonly type = '[BudgetPosts] Get budgetpostyear';
  constructor(public budgetPostId: number, public budgetYearId: number) {}
}

export class GetBudgetPostHolders {
  static readonly type = '[BudgetPosts] Get budgetpostholders';
}

export class GetBudgetPostHoldersById {
  static readonly type = '[BudgetPosts] Get budgetpostholders';
  constructor(public id: number) {}
}

export class GetBudgetPostsByUserId {
  static readonly type = '[BudgetPosts] Get budgetpostByUserID';
  constructor(public id: number) {}
}

export class SetBudgetYearBudgetPost {
  static readonly type = '[BudgetPosts] Set budgetyear';
  constructor(public id: number) {}
}

export class ArchiveBudgetPostById {
  static readonly type = '[BudgetPosts] Archive budgetpost by id';
  constructor(public payload: boolean, public id: number) {}
}
