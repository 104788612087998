import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Festival } from 'src/app/models/festival.interface';
import { FestivalService } from 'src/app/services/festival.service';
import {
  CreateFestival,
  GetFestivalById,
  GetFestivals,
  UpdateFestival,
} from '../actions/festival.action';

export class FestivalStateModel {
  festivals: Festival[];
  selectedFestival: Festival | null;
  isLoading: boolean;
}

@State<FestivalStateModel>({
  name: 'festival',
  defaults: {
    festivals: [],
    selectedFestival: null,
    isLoading: false,
  },
})
@Injectable()
export class FestivalState {
  constructor(private festivalService: FestivalService) {}

  @Selector()
  static festivals(state: FestivalStateModel): Festival[] {
    return state.festivals;
  }

  @Selector()
  static isLoading(state: FestivalStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static selectedFestival(state: FestivalStateModel) {
    return state.selectedFestival;
  }

  @Action(GetFestivals)
  getFestivals(ctx: StateContext<FestivalStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.festivalService.getFestivals().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          festivals: data,
        });
      })
    );
  }

  @Action(GetFestivalById)
  getFestivalsById(
    ctx: StateContext<FestivalStateModel>,
    action: GetFestivalById
  ) {
    return this.festivalService.getFestivalById(action.id).pipe(
      tap((data) => {
        ctx.patchState({
          selectedFestival: data,
        });
      })
    );
  }

  @Action(CreateFestival)
  createFestival(
    ctx: StateContext<FestivalStateModel>,
    { payload }: CreateFestival
  ) {
    return this.festivalService.createFestival(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          festivals: [...state.festivals, data],
          selectedFestival: data,
        });
      })
    );
  }

  @Action(UpdateFestival)
  updateFestival(
    ctx: StateContext<FestivalStateModel>,
    { payload, id }: UpdateFestival
  ) {
    return this.festivalService.updateFestival(payload, id).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          festivals: [...state.festivals, data],
          selectedFestival: data,
        });
      })
    );
  }
}
