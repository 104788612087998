import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Order } from 'src/app/models/order.interface';
import { OrderService } from 'src/app/services/order.service';
import {
  CreateOrder,
  DeleteOrderById,
  GetOrderById,
  GetOrders,
  SetBudgetYearOrder,
  UpdateOrder,
} from '../actions/order.action';

export class OrderStateModel {
  orders: Order[];
  order: Order;
  isLoading: boolean;
  budgetYear: number;
}

@State<OrderStateModel>({
  name: 'order',
  defaults: {
    orders: [],
    order: {} as Order,
    isLoading: false,
    budgetYear: 0,
  },
})
@Injectable()
export class OrderState {
  constructor(private orderService: OrderService) {}

  @Selector()
  static orders(state: OrderStateModel) {
    if (state.budgetYear && state.budgetYear != 0) {
      return state.orders.filter(
        (order) => order.budget_year.id === state.budgetYear
      );
    } else {
      return state.orders;
    }
  }

  @Selector()
  static allOrders(state: OrderStateModel) {
    return state.orders;
  }

  @Selector()
  static isLoading(state: OrderStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static order(state: OrderStateModel) {
    return state.order;
  }

  @Selector()
  static ordersByUserId(state: OrderStateModel) {
    if (state.budgetYear && state.budgetYear != 0) {
      return (id: number) =>
        state.orders.filter(
          (order) =>
            order.budget_year.id == state.budgetYear &&
            order.creator.id == id &&
            order.approved == false
        );
    } else {
      return (id: number) =>
        state.orders.filter((order) => order.creator.id == id);
    }
  }

  // @Selector()
  // static ordersByUsersBudgetpost(state: OrderStateModel) {
  //   if (state.budgetYear && state.budgetYear != 0) {
  //     return (budgetPostIds: number[]) =>
  //       state.orders.filter(
  //         (order) =>
  //           budgetPostIds.includes(order.budget_post.id) &&
  //           order.budget_year.id == state.budgetYear
  //       );
  //   } else {
  //     return (budgetPostIds: number[]) =>
  //       state.orders.filter((order) =>
  //         budgetPostIds.includes(order.budget_post.id)
  //       );
  //   }
  // }

  @Selector()
  static totalOrders(state: OrderStateModel) {
    return state.orders.length;
  }

  @Selector()
  static ordersApprovedBudgetYear(state: OrderStateModel) {
    let percentage = 0;
    if (state.orders.length > 0) {
      let orders = state.orders;

      if (state.budgetYear && state.budgetYear != 0) {
        orders = state.orders.filter(
          (order) => order.budget_year.id === state.budgetYear
        );
      }

      let arrLength = orders.length;
      let totalApproved = orders.filter((order) => order.approved).length;
      percentage = totalApproved / arrLength;
    }
    return percentage;
  }

  calculatePercentage(arr: any[]) {}

  @Action(SetBudgetYearOrder)
  setBudgetYear(
    ctx: StateContext<OrderStateModel>,
    { id }: SetBudgetYearOrder
  ) {
    ctx.patchState({ budgetYear: id });
  }

  @Action(GetOrders)
  getOrders(ctx: StateContext<OrderStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    return this.orderService.getOrders().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          orders: data,
        });
      })
    );
  }

  @Action(GetOrderById)
  getOrdersById(ctx: StateContext<OrderStateModel>, { id }: GetOrderById) {
    return this.orderService.getOrderById(id).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          order: data,
        });
      })
    );
  }

  @Action(CreateOrder)
  createOrder(ctx: StateContext<OrderStateModel>, { payload }: CreateOrder) {
    return this.orderService.createOrder(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          orders: [...state.orders, data],
        });
      })
    );
  }

  @Action(UpdateOrder)
  updateOrder(
    ctx: StateContext<OrderStateModel>,
    { payload, id }: UpdateOrder
  ) {
    return this.orderService.updateOrder(id, payload).pipe(
      tap((data) => {
        const state = ctx.getState();

        // const Orders = [...state.Orders];
        // Orders[i] = payload;

        ctx.setState({
          ...state,
          // Orders: Orders,
          orders: [],
        });
      })
    );
  }

  @Action(DeleteOrderById)
  deleteOrderById(ctx: StateContext<OrderStateModel>, { id }: DeleteOrderById) {
    return this.orderService.deleteOrder(id).pipe(
      tap((data) => {
        const state = ctx.getState();
        const filteredArray = state.orders.filter((item) => item.id !== id);

        ctx.setState({
          ...state,
          orders: filteredArray,
        });
      })
    );
  }
}
