import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { SupplierService } from 'src/app/services/supplier.service';
import {
  CreateSupplier,
  DeleteSupplier,
  GetSupplierById,
  GetSuppliers,
  GetSuppliersToCheck,
  UpdateSupplier,
} from '../actions/supplier.action';
import { Supplier } from 'src/app/models/supplier.interface';

export class SupplierStateModel {
  suppliers: Supplier[];
  suppliersCheckList: Supplier[];
  selectedSupplier: Supplier | null;
  isLoading: boolean;
}

@State<SupplierStateModel>({
  name: 'supplier',
  defaults: {
    suppliers: [],
    suppliersCheckList: [],
    selectedSupplier: null,
    isLoading: false,
  },
})
@Injectable()
export class SupplierState {
  constructor(private supplierService: SupplierService) {}

  @Selector()
  static suppliers(state: SupplierStateModel): Supplier[] {
    return state.suppliers;
  }

  @Selector()
  static suppliersCheckList(state: SupplierStateModel): Supplier[] {
    return state.suppliersCheckList;
  }

  @Selector()
  static isLoading(state: SupplierStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static selectedSupplier(state: SupplierStateModel) {
    return state.selectedSupplier;
  }

  @Action(GetSuppliers)
  getSuppliers(ctx: StateContext<SupplierStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.supplierService.getSuppliers().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          suppliers: data,
        });
      })
    );
  }

  @Action(GetSuppliersToCheck)
  getSuppliersToCheck(ctx: StateContext<SupplierStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.supplierService.getSuppliersCheckList().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          suppliersCheckList: data,
        });
      })
    );
  }

  @Action(GetSupplierById)
  getSuppliersById(
    ctx: StateContext<SupplierStateModel>,
    action: GetSupplierById
  ) {
    return this.supplierService.getSupplierById(action.id).pipe(
      tap((data) => {
        ctx.patchState({
          selectedSupplier: data,
        });
      })
    );
  }

  @Action(CreateSupplier)
  createSupplier(
    ctx: StateContext<SupplierStateModel>,
    { payload }: CreateSupplier
  ) {
    return this.supplierService.createSupplier(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          suppliers: [...state.suppliers, data],
          selectedSupplier: data,
        });
      })
    );
  }

  @Action(DeleteSupplier)
  deleteSupplier(
    ctx: StateContext<SupplierStateModel>,
    { id }: GetSupplierById
  ) {
    ctx.patchState({ isLoading: true });

    return this.supplierService.deleteSupplier(id).pipe(
      tap((data) => {
        const state = ctx.getState();

        const filteredArray = state.suppliersCheckList.filter(
          (item) => item.id !== id
        );

        ctx.patchState({
          isLoading: false,
          suppliersCheckList: filteredArray,
        });
      })
    );
  }

  @Action(UpdateSupplier)
  updateSupplier(
    ctx: StateContext<SupplierStateModel>,
    { payload, id }: UpdateSupplier
  ) {
    return this.supplierService.updateSupplier(payload, id).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          suppliers: [...state.suppliers, data],
          selectedSupplier: data,
        });
      })
    );
  }
}
