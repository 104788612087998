import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { FestivalForm } from '../models/festival.interface';

const URL = '/api/festivals';

@Injectable({
  providedIn: 'root',
})
export class FestivalService {
  constructor(private http: HttpClient) {}

  getFestivals() {
    return this.http.get<any>(environment.apiUrl + URL);
  }

  getFestivalById(id: number) {
    return this.http.get<any>(environment.apiUrl + `${URL}/${id}`);
  }

  createFestival(festival: FestivalForm) {
    return this.http.post<any>(environment.apiUrl + URL, festival);
  }

  updateFestival(festival: FestivalForm, id: number) {
    return this.http.put<any>(environment.apiUrl + `${URL}/${id}`, festival);
  }
}
