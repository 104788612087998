<div class="app">
  <aside class="app__sidebar" *ngIf="currentUser">
    <header [routerLink]="['dashboard']">
      <img src="assets/images/logohasselt.jpg" />
    </header>

    <nav>
      <ul>
        <li>
          <app-budget-year-selector
            name="budget_year"
            [label]="false"
            [ngModel]="activeRole"
            (ngModelChange)="updateActiveRole($event)"
          >
          </app-budget-year-selector>
        </li>
        <li class="app__sidebar__divider"></li>
        <li>
          <a class="button is-info" [routerLink]="['orders/create']">
            Bestelbon aanmaken
          </a>
        </li>
        <li class="app__sidebar__divider"></li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['dashboard']"
            ><i class="material-icons">dashboard</i> Dashboard</a
          >
        </li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['orders']"
            ><i class="material-icons">settings</i> Bestelbonnen</a
          >
        </li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['suppliers']"
            ><i class="material-icons">local_shipping</i> Leveranciers</a
          >
        </li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['suppliers-check']"
            ><i class="material-icons">manage_search</i> Leveranciers te
            controleren
          </a>
        </li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['articles']"
            ><i class="material-icons">inventory</i> Events</a
          >
        </li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['contracts']"
            ><i class="material-icons">description</i> Contracten</a
          >
        </li>
        <li>
          <a routerLinkActive="is-active" [routerLink]="['festivals']"
            ><i class="material-icons">festival</i> Festivals</a
          >
        </li>
        <li class="app__sidebar__divider"></li>
        <li>
          <a class="no-link"
            ><i class="material-icons">settings</i> Instellingen</a
          >
          <ul>
            <li>
              <a routerLinkActive="is-active" [routerLink]="['settings/users']"
                >Gebruikers</a
              >
            </li>
            <li>
              <a routerLinkActive="is-active" [routerLink]="['settings/roles']"
                >Rollen</a
              >
            </li>
            <li>
              <a
                routerLinkActive="is-active"
                [routerLink]="['settings/budgetpost']"
                >Budgetposten</a
              >
            </li>
            <li>
              <a
                routerLinkActive="is-active"
                [routerLink]="['settings/budgetyear']"
                >Budgetjaren</a
              >
            </li>
            <li>
              <a
                routerLinkActive="is-active"
                [routerLink]="['settings/account']"
                >Account</a
              >
            </li>
          </ul>
        </li>
        <li class="app__sidebar__divider"></li>
        <li class="app__sidebar__profile">
          <a routerLinkActive="is-active" [routerLink]="['settings/account']"
            >{{ currentUser.first_name }} {{ currentUser.last_name }}<br />
            <small
              >{{ currentUser.role.role
              }}{{ currentUser.is_admin ? " - Admin" : "" }}</small
            >
          </a>
        </li>
        <li><a (click)="logout()">Uitloggen</a></li>
      </ul>
    </nav>
  </aside>

  <div class="app__main">
    <div class="app__main__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
