import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  BudgetPost,
  BudgetPostIndexList,
} from '../models/budgetPost.interface';

@Injectable({ providedIn: 'root' })
export class BudgetPostService {
  constructor(private http: HttpClient) {}

  getBudgetPosts() {
    return this.http.get<any>(environment.apiUrl + '/api/budgetposts');
  }

  getBudgetPostList(budgetYearId: number) {
    return this.http.get<any>(
      environment.apiUrl + '/api/budgetposts/list/all/' + budgetYearId
    );
  }

  getBudgetPostById(id: number) {
    return this.http.get<any>(environment.apiUrl + `/api/budgetposts/${id}`);
  }

  createBudgetPost(budgetPost: BudgetPost) {
    return this.http.post<any>(
      environment.apiUrl + '/api/budgetposts',
      budgetPost
    );
  }

  updateBudgetPost(id: number, budgetPost: BudgetPost) {
    return this.http.put<any>(
      environment.apiUrl + `/api/budgetposts/${id}`,
      budgetPost
    );
  }

  archiveBudgetPost(isArchive: boolean, id: number) {
    return this.http.put<any>(
      environment.apiUrl + `/api/budgetposts/archive/${id}`,
      {
        is_archived: isArchive,
      }
    );
  }

  updateBudgets(id: number, budgets: any) {
    return this.http.put<any>(
      environment.apiUrl + `/api/budgetposts/updatebudgets/${id}`,
      budgets
    );
  }

  createBudget(id: number, budgetPost: BudgetPost) {
    return this.http.post<any>(
      environment.apiUrl + `/api/budgetposts/createbudget/${id}`,
      budgetPost
    );
  }

  getBudgetPostBudgetYearById(budgetPostId: number, budgetYearId: number) {
    return this.http.get<any>(
      environment.apiUrl +
        `/api/budgetposts/${budgetPostId}/budgetyears/${budgetYearId}`
    );
  }
  getBudgetPostHolders() {
    return this.http.get<any>(environment.apiUrl + '/api/budgetpostholders');
  }

  getBudgetPostHoldersById(id: number) {
    return this.http.get<any>(environment.apiUrl + `/api/budgetposts/${id}`);
  }

  getAnnaulBudgetReviewById(id: number) {
    return this.http.get<any>(
      environment.apiUrl + `/api/budgetposts/annnualbudgetreview/${id}`
    );
  }

  getBudgetPostsByUserId(id: number) {
    return this.http.get<any>(
      environment.apiUrl + `/api/budgetposts/user/${id}`
    );
  }

  syncList(list: BudgetPostIndexList[]) {
    return this.http.post<any>(
      environment.apiUrl + '/api/budgetposts/sync/list',
      list
    );
  }
}
