import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Contract } from 'src/app/models/contract.interface';
import { ContractService } from 'src/app/services/contract.service';
import {
  CreateContract,
  GetContracts,
  UpdateContract,
} from 'src/app/store/actions/contract.action';

@Component({
  selector: 'app-contract-upsert',
  templateUrl: './contract-upsert.component.html',
  styleUrls: ['./contract-upsert.component.scss'],
})
export class ContractUpsertComponent implements OnInit {
  form: FormGroup;
  loading: boolean = false;
  error: string = '';
  title: string = 'Contract';
  readonly: boolean = false;

  constructor(
    private contractService: ContractService,
    private store: Store,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ContractUpsertComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.id) {
      this.getContract(this.data.id);
    } else {
      this.initForm(null);
    }

    this.readonly = this.data.readonly;
  }

  getContract(id: number): void {
    this.contractService.getContractById(id).subscribe((data) => {
      this.initForm(data);
    });
  }

  initForm(contract: Contract | null): void {
    this.form = this.fb.group({
      title: [contract?.title || '', Validators.required],
      description: [contract?.description || '', Validators.maxLength(1000)],
      file: ['', Validators.required],
    });

    this.readonly && this.form.disable();
  }

  save(contract: any): void {
    this.loading = true;

    const req = {
      title: contract.title,
      description: contract.description,
      file: this.form.get('file')?.value,
    };

    if (this.data.id) {
      this.store.dispatch(new UpdateContract(req, this.data.id)).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Contract is gewijzigd');
          this.dialogRef.close();

          this.store.dispatch(new GetContracts());
        },
        (error) => {
          this.toastr.error(error.error, 'Er is iets misgegaan!');
          this.error = error.error.message ? error.error.message : error.error;
          this.loading = false;
        }
      );
    } else {
      this.store.dispatch(new CreateContract(req)).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Contract is toegevoegd');
          this.dialogRef.close();

          this.store.dispatch(new GetContracts());
        },
        (error) => {
          this.toastr.error(error.error, 'Er is iets misgegaan!');
          this.error = error.error.message ? error.error.message : error.error;
          this.loading = false;
        }
      );
    }
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        file: file,
      });
      this.form.markAsDirty();
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get f() {
    return this.form.controls;
  }
}
