import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Role } from 'src/app/models/role.interface';
import { RoleService } from 'src/app/services/role.service';
import { GetRoles } from '../actions/role.action';

export class RoleStateModel {
  roles: Role[];
  isLoading: boolean;
}

@State<RoleStateModel>({
  name: 'role',
  defaults: {
    roles: [],
    isLoading: false,
  },
})
@Injectable()
export class RoleState {
  constructor(private roleServie: RoleService) {}

  @Selector()
  static roles(state: RoleStateModel) {
    return state.roles;
  }

  @Selector()
  static isLoading(state: RoleStateModel): boolean {
    return state.isLoading;
  }

  @Action(GetRoles)
  getRoles(ctx: StateContext<RoleStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.roleServie.getRoles().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          roles: data,
        });
      })
    );
  }
}
