<h1 mat-dialog-title>Leverancier</h1>
<div mat-dialog-content>
  <em *ngIf="loading" class="las la-spinner la-spin la-5x"></em>

  <div class="supplier__wrap" *ngIf="!loading">
    <table *ngIf="supplier; else noData">
      <tr>
        <td class="supplier__header">Naam:</td>
        <td>{{ supplier.name }}</td>
      </tr>

      <tr>
        <td class="supplier__header">Adres:</td>
        <td>
          {{ supplier.address && supplier.address + " ," }}
          {{ supplier.zipcode }}
          {{ supplier.locality && supplier.locality + "," }}
          {{ supplier.country }}
        </td>
      </tr>

      <tr>
        <td class="supplier__header">VAT:</td>
        <td>{{ supplier.vat_number }}</td>
      </tr>
      <tr>
        <td class="supplier__header">E-mail:</td>
        <td>{{ supplier.email }}</td>
      </tr>
      <tr>
        <td class="supplier__header">Telefoon:</td>
        <td>{{ supplier.phone }}</td>
      </tr>
      <tr>
        <td class="supplier__header">Website:</td>
        <td>{{ supplier.website }}</td>
      </tr>
      <tr>
        <td class="supplier__header">Iban:</td>
        <td>{{ supplier.iban }}</td>
      </tr>
      <tr>
        <td class="supplier__header">Andere informatie:</td>
        <td>{{ supplier.other_information }}</td>
      </tr>
    </table>

    <ng-template #noData>
      <div>Kon de gevraagde leverancier niet vinden.</div>
    </ng-template>
  </div>
</div>
<div mat-dialog-actions style="justify-content: space-between">
  <button class="button is-text" mat-dialog-close>Annuleren</button>
</div>
