<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <em *ngIf="!editForm" class="las la-spinner la-spin la-5x"></em>
  <form *ngIf="editForm" [formGroup]="editForm">
    <div class="field">
      <label class="label">Budgetpost titel</label>
      <div class="control has-icons-left">
        <input
          class="input"
          [ngClass]="{
            'is-danger':
              f['title'].invalid && (f['title'].dirty || f['title'].touched)
          }"
          type="text"
          name="title"
          placeholder="Titel"
          formControlName="title"
        />
        <div class="icon is-small is-left">
          <i class="las la-signature"></i>
        </div>
      </div>
      <div
        class="errors"
        *ngIf="f['title'].invalid && (f['title'].dirty || f['title'].touched)"
      >
        <p class="help is-danger" *ngIf="f['title'].errors?.['required']">
          Titel is verplicht!
        </p>
        <p class="help is-danger" *ngIf="f['title'].errors?.['maxlength']">
          Titel mag maar maximum 255 karakters bevatten!
        </p>
      </div>
    </div>

    <div style="margin-top: 0.75rem">
      <label class="label">Budgethouders<span>*</span></label>
      <ng-select
        [items]="budgetHolders"
        bindLabel="first_name"
        bindValue="id"
        appendTo="body"
        [multiple]="true"
        [closeOnSelect]="false"
        formControlName="budget_holders"
      >
        <ng-template ng-label-tmp let-item="item">
          <span>{{ item.first_name + " " + item.last_name }}</span>
        </ng-template>
        <ng-template
          ng-option-tmp
          let-item="item"
          let-search="searchTerm"
          let-index="index"
        >
          <span>{{ item.first_name + " " + item.last_name }}</span>
        </ng-template>
      </ng-select>
      <div
        class="errors"
        *ngIf="
          f['budget_holders'].invalid &&
          (f['budget_holders'].dirty || f['budget_holders'].touched)
        "
      >
        <p
          class="help is-danger"
          *ngIf="f['budget_holders'].errors?.['required']"
        >
          Minimum 1 budgethouder is verplicht!
        </p>
      </div>
    </div>

    <p *ngIf="error" class="help is-danger">{{ error }}!</p>
  </form>
</div>
<div mat-dialog-actions style="justify-content: space-between">
  <button class="button is-text" mat-dialog-close>Annuleer</button>
  <button
    *ngIf="editForm"
    class="button is-info"
    (click)="saveChanges(editForm.value)"
    [disabled]="editForm.invalid || loading"
  >
    <span>Opslaan</span>
    <span class="icon" *ngIf="loading">
      <i class="las la-spinner la-spin"></i>
    </span>
  </button>
</div>
