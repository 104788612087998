<div class="validation-wrapper" *ngIf="!hidden && !order.approved">
  <p *ngIf="revaluate">Deze bestelbon is al goedgekeurd/afgekeurd. Wil je deze herzien?</p>
  <p *ngIf="evaluate">Deze bestelbon staat klaar om te evalueren.</p>
  <button class="button is-success" (click)="openDialog()">
    <span class="icon">
      <i class="las la-check-circle"></i>
    </span>
    <span *ngIf="evaluate">Evalueren</span>
    <span *ngIf="revaluate">Herzien</span>
  </button>
</div>
