import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Order } from 'src/app/models/order.interface';
import { OrderService } from 'src/app/services/order.service';
import { OrderState } from 'src/app/store/states/order.state';



@Component({
  selector: 'app-linked-orders',
  templateUrl: './linked-orders.component.html',
  styleUrls: ['./linked-orders.component.scss'],
})

export class LinkedOrdersComponent implements OnInit {
  loading: boolean = false;
  orders: Order[] = [];

  constructor(
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders(): void {
    this.loading = true;
    this.orderService.getLinkedOrders(this.data.type, this.data.id).subscribe(
      (orders: Order[]) => {
        this.orders = orders;
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching linked orders:', error);
        this.loading = false;
      }
    );
  }
}
