<div class="fied" style="margin-bottom: 0.75rem">
  <label class="label" *ngIf="label">
    Contract <span *ngIf="required">*</span>
  </label>
  <div class="control">
    <div class="select is-fullwidth">
      <select
        [(ngModel)]="input"
        (ngModelChange)="onChange($event)"
        (blur)="onTouch()"
        [disabled]="isDisabled()"
      >
        <option [value]="null"></option>
        <option *ngFor="let item of contracts$ | async" [value]="item.id">
          {{ item.title }}
        </option>
      </select>
    </div>
    <button
      class="button is-success"
      style="margin-left: 0.5rem"
      type="button"
      (click)="openContractUpsertDialog()"
    >
      <span class="icon">
        <i class="las la-plus"></i>
      </span>
    </button>
  </div>
</div>
