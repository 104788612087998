<div class="fied" style="margin-bottom: 0.75rem;">
  <label class="label">Rol</label>
  <div class="control has-icons-left">
    <div class="select is-fullwidth">
      <select [(ngModel)]="input" (ngModelChange)="onChange($event)" (blur)="onTouch()" [disabled]="isDisabled()">
        <option *ngFor="let item of roles$ | async" [value]="item.id">{{item.role}}</option>
      </select>
    </div>
    <div class="icon is-small is-left">
      <i class="las la-user-tag"></i>
    </div>
  </div>
</div>
