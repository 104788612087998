import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ContractForm } from '../models/contract.interface';
import { Observable } from 'rxjs';

const URL = '/api/contracts';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private http: HttpClient) {}

  getContracts() {
    return this.http.get<any>(environment.apiUrl + URL);
  }

  getContractById(id: number) {
    return this.http.get<any>(environment.apiUrl + `${URL}/${id}`);
  }

  createContract(contract: ContractForm) {
    const formData = this.contractToFormData(contract);
    return this.http.post<any>(environment.apiUrl + URL, formData);
  }

  updateContract(contract: ContractForm, id: number) {
    const formData = this.contractToFormData(contract);
    return this.http.post<any>(environment.apiUrl + `${URL}/${id}`, formData);
  }

  private contractToFormData(contract: ContractForm): FormData {
    const formData = new FormData();
    for (var key in contract) {
      formData.append(key, contract[key as keyof ContractForm]);
    }

    return formData;
  }

  downloadContract(id: number): Observable<Blob> {
    return this.http.get(environment.apiUrl + `${URL}/download/${id}`, {
      responseType: 'blob',
    });
  }
}
