import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPROVAL_TYPES } from 'src/app/models/ApprovalType.enum';
import { BudgetPost } from 'src/app/models/budgetPost.interface';
import { Order } from 'src/app/models/order.interface';
import { ROLES } from 'src/app/models/roles.enum';
import { AuthService } from 'src/app/services/auth.service';
import { EvaluateOrderModalComponent } from '../evaluate-order-modal/evaluate-order-modal.component';

@Component({
  selector: 'app-evaluate-order',
  templateUrl: './evaluate-order.component.html',
  styleUrls: ['./evaluate-order.component.scss'],
})
export class EvaluateOrderComponent implements OnInit {
  @Input() order: Order;
  @Output() refresh = new EventEmitter<boolean>();
  user;
  evaluate: boolean;
  revaluate: boolean;
  hidden: boolean;

  constructor(public dialog: MatDialog, private authService: AuthService) {
    this.user = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.checkStatusAndRoles();
  }

  checkStatusAndRoles() {
    this.hidden = true;
    const orderStatusArray = this.order.statuses.filter(
      (status: any) => status.order_id == this.order.id
    );

    // Check allowed user roles
    switch (this.user.role.id) {
      case ROLES.BUDGETHOLDER: {
        // Check if the budgetholder's budgetpost is in the order's budgetpost
        if (
          this.user.budget_posts.some(
            (bp: BudgetPost) => bp.id === this.order.products[0].budget_post.id
          )
        ) {
          // User is allowed to evaluate
          this.hidden = false;
          // Get the status of the order for a budgetholder
          let budgetOrderStatus = orderStatusArray.find(
            (status: any) => status.role.id === this.user.role.id
          );

          // Check if order has been evaluated by other budgetholder
          if (budgetOrderStatus && budgetOrderStatus.approved) {
            this.evaluate = false;
            this.revaluate = true;
          } else {
            this.revaluate = false;
            this.evaluate = true;
          }
        }
        break;
      }

      case ROLES.FINANCIALMAINTAINER: {
        this.evaluateOrder(
          orderStatusArray,
          ROLES.BUDGETHOLDER,
          ROLES.FINANCIALMAINTAINER
        );
        break;
      }

      case ROLES.DIRECTOR: {
        this.evaluateOrder(
          orderStatusArray,
          ROLES.FINANCIALMAINTAINER,
          ROLES.DIRECTOR
        );
        break;
      }
      default:
        this.hidden = true;
        break;
    }
  }

  evaluateOrder(status: any[], roleIdPrevious: number, roleIdNext: number) {
    // check if previous role approved
    let orderStatus = status.find(
      (status: any) => status.role.id === roleIdPrevious
    );

    // Check if order has been approved by financieel beheerder, if not, this role cannot evaluate yet
    if (orderStatus && orderStatus?.approved === APPROVAL_TYPES.APPROVED) {
      this.hidden = false;
      // Get status for currentRole
      let nextStatus = status.find(
        (status: any) => status.role.id === roleIdNext
      );
      if (nextStatus && nextStatus.approved) {
        this.evaluate = false;
        this.revaluate = true;
      } else {
        this.revaluate = false;
        this.evaluate = true;
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EvaluateOrderModalComponent, {
      width: '500px',
      data: { orderId: this.order.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.hidden = false;
        this.evaluate = false;
        this.revaluate = true;
        this.refresh.emit(true);
      }
    });
  }
}
