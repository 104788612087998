import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { APPROVAL_TYPES } from 'src/app/models/ApprovalType.enum';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-evaluate-order-modal',
  templateUrl: './evaluate-order-modal.component.html',
  styleUrls: ['./evaluate-order-modal.component.scss'],
})
export class EvaluateOrderModalComponent implements OnInit {
  loading = false;
  comment: string = '';
  error: string = '';

  constructor(
    private orderService: OrderService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<EvaluateOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  save(approved: boolean) {
    if (!approved && !(this.comment.length > 0)) {
      this.error = 'Bij het afkeuren moet er een opmerking gegeven worden!';
      return;
    }
    this.loading = true;

    const req = {
      comment: this.comment,
      approved: approved ? APPROVAL_TYPES.APPROVED : APPROVAL_TYPES.REJECTED,
    };

    this.orderService.evaluate(this.data.orderId, req).subscribe(
      (data) => {
        this.loading = false;
        this.toastr.success('Bestelbon is geëvalueerd!');
        this.dialogRef.close(true);
      },
      (error) => {
        this.toastr.error(error.error, 'Er is iets misgegaan!');
        this.loading = false;
      }
    );
  }
}
