<div class="table-wrapper">
  <div>
    <table
      *ngIf="sortedData.length > 0; else noData"
      class="table clickable"
      matSort
      (matSortChange)="sortData($event)"
    >
      <thead>
        <tr>
          <th mat-sort-header="title">Titel</th>
          <th>Gespendeerd</th>
          <th mat-sort-header="budget">Budget</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let element of sortedData"
          [routerLink]="['/settings/budgetpost', +element.id]"
        >
          <td>{{ element.title }}</td>
          <td>
            <progress
              class="progress is-small is-info"
              [class]="
                getLimitSpending(
                  element.products_price || 0,
                  calculateMaxSpending(element.budgets)
                )
              "
              [value]="element.products_price"
              #tooltip="matTooltip"
              [matTooltip]="
                (element.products_price || 0 | currency : 'EUR') +
                ' / ' +
                (calculateMaxSpending(element.budgets) | currency : 'EUR') +
                ' gespendeerd'
              "
              matTooltipPosition="above"
              [max]="calculateMaxSpending(element.budgets)"
            ></progress>
          </td>
          <td>{{ calculateBudget(element.budgets) }}</td>
          <td width="1" (click)="$event.stopPropagation()">
            <button class="button is-small" [matMenuTriggerFor]="menu">
              <span class="icon is-small">
                <i class="las la-ellipsis-v"></i>
              </span>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openDialog(element.id)">
                Bewerken
              </button>
              <button
                mat-menu-item
                (click)="archive(element.id, !element.is_archived)"
              >
                {{ element.is_archived ? "Heractiveren" : "Archiveren" }}
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>

    <ng-template #noData>
      <app-no-data
        title="Geen budgetposten gevonden"
        description="Er zijn geen budgetposten gevonden. Druk op de knop om je eerste budgetpost toe te voegen."
      >
        <button class="button is-info" (click)="openDialog()">
          <span class="icon">
            <i class="las la-plus"></i>
          </span>
          <span>Budgetpost</span>
        </button>
      </app-no-data>
    </ng-template>
  </div>
</div>
