import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Contract } from 'src/app/models/contract.interface';
import { GetContracts } from 'src/app/store/actions/contract.action';
import { ContractState } from 'src/app/store/states/contract.state';
import { ContractUpsertComponent } from '../../contracts/contract-upsert/contract-upsert.component';

@Component({
  selector: 'app-contract-selector',
  templateUrl: './contract-selector.component.html',
  styleUrls: ['./contract-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContractSelectorComponent),
      multi: true,
    },
  ],
})
export class ContractSelectorComponent implements ControlValueAccessor {
  dataSource: Contract[] = [];
  input = '';
  disabled = false;
  @Input()
  label: boolean = true;
  @Input()
  required: boolean = false;

  @Select(ContractState.contracts)
  contracts$: Observable<Contract[]>;

  constructor(public dialog: MatDialog) {}

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(val: string) {
    console.log('set value', val);
    this.input = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    console.log('writeValue', value);
    this.value = value;
  }

  registerOnChange(fn: any) {
    console.log('registerOnChange');
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    console.log('registerOnTouched');
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  isDisabled(): boolean {
    return this.disabled;
  }

  openContractUpsertDialog(): void {
    this.dialog.open(ContractUpsertComponent, {
      width: '500px',
      data: { id: null },
    });
  }
}
