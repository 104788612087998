import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Budget } from 'src/app/models/budget.interface';
import { BudgetPost } from 'src/app/models/budgetPost.interface';
import { Order } from 'src/app/models/order.interface';
import { BudgetPostService } from 'src/app/services/budgetpost.service';
import {
  ArchiveBudgetPostById,
  CreateBudget,
  CreateBudgetPost,
  GetBudgetPostById,
  GetBudgetPosts,
  GetBudgetPostsByUserId,
  GetBudgetPostsList,
  SetBudgetYearBudgetPost,
  UpdateBudgetPost,
  UpdateBudgets,
} from '../actions/budget-post.action';

export class BudgetPostStateModel {
  budgetPosts: BudgetPost[];
  budgetPostList: BudgetPost[];
  budgetPostsUser: BudgetPost[];
  isLoading: boolean;
  isLoadingBudgets: boolean;
  budgetYear: number;
  budgetPost: BudgetPost;
}

@State<BudgetPostStateModel>({
  name: 'budgetPost',
  defaults: {
    budgetPosts: [],
    budgetPostList: [],
    budgetPostsUser: [],
    isLoading: false,
    isLoadingBudgets: false,
    budgetYear: 0,
    budgetPost: {} as any,
  },
})
@Injectable()
export class BudgetPostState {
  constructor(private budgetPostService: BudgetPostService) {}

  @Selector()
  static budgetPosts(state: BudgetPostStateModel) {
    return state.budgetPosts;
  }

  @Selector()
  static unarchivedBudgetPosts(state: BudgetPostStateModel) {
    return state.budgetPosts.filter(
      (budgetPost: BudgetPost) => budgetPost.is_archived == false
    );
  }

  @Selector()
  static budgetPost(state: BudgetPostStateModel) {
    return state.budgetPost;
  }

  @Selector()
  static budgetPostsWithBudgetsForBudgetYear(state: BudgetPostStateModel) {
    return state.budgetPostList.map((bp: BudgetPost) => {
      return {
        ...bp,
        budgets: bp.budgets.filter(
          (budget: Budget) => budget.budget_year === state.budgetYear
        ),
      };
    });
  }

  @Selector()
  static budgetPostsUser(state: BudgetPostStateModel) {
    return state.budgetPostsUser.map((bp: BudgetPost) => {
      return {
        ...bp,
        budgets: bp.budgets.filter(
          (budget: Budget) => budget.budget_year === state.budgetYear
        ),
      };
    });
  }

  @Selector()
  static isLoading(state: BudgetPostStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static budgetYear(state: BudgetPostStateModel): number {
    return state.budgetYear;
  }

  @Action(SetBudgetYearBudgetPost)
  setBudgetYear(
    ctx: StateContext<BudgetPostStateModel>,
    { id }: SetBudgetYearBudgetPost
  ) {
    ctx.patchState({ budgetYear: id, isLoading: true });
    return (
      !isNaN(id) &&
      this.budgetPostService.getBudgetPostList(+ctx.getState().budgetYear).pipe(
        tap((data) => {
          ctx.patchState({
            isLoading: false,
            budgetPostList: data,
          });
        })
      )
    );
  }

  @Action(GetBudgetPosts)
  getBudgetPosts(ctx: StateContext<BudgetPostStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    return this.budgetPostService.getBudgetPosts().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          budgetPosts: data,
        });
      })
    );
  }

  @Action(GetBudgetPostsList)
  getBudgetPostsList(ctx: StateContext<BudgetPostStateModel>) {
    ctx.patchState({
      isLoading: true,
    });

    return (
      !isNaN(ctx.getState().budgetYear) &&
      this.budgetPostService.getBudgetPostList(+ctx.getState().budgetYear).pipe(
        tap((data) => {
          ctx.patchState({
            isLoading: false,
            budgetPostList: data,
          });
        })
      )
    );
  }

  @Action(GetBudgetPostById)
  getBudgetPostsById(
    ctx: StateContext<BudgetPostStateModel>,
    action: GetBudgetPostById
  ) {
    return this.budgetPostService.getBudgetPostById(action.id).pipe(
      tap((data) => {
        ctx.patchState({
          budgetPost: data,
        });
      })
    );
  }

  @Action(GetBudgetPostsByUserId)
  GetBudgetPostsByUserId(
    ctx: StateContext<BudgetPostStateModel>,
    action: GetBudgetPostsByUserId
  ) {
    return this.budgetPostService.getBudgetPostsByUserId(action.id).pipe(
      tap((data) => {
        const state = ctx.getState();

        ctx.setState({
          ...state,
          budgetPostsUser: data,
        });
      })
    );
  }

  @Action(CreateBudgetPost)
  createBudgetPost(
    ctx: StateContext<BudgetPostStateModel>,
    { payload }: CreateBudgetPost
  ) {
    return this.budgetPostService.createBudgetPost(payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          budgetPosts: [...state.budgetPosts],
        });
      })
    );
  }

  @Action(UpdateBudgetPost)
  updateBudgetPost(
    ctx: StateContext<BudgetPostStateModel>,
    { payload, id }: UpdateBudgetPost
  ) {
    return this.budgetPostService.updateBudgetPost(id, payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          budgetPosts: [...state.budgetPosts],
        });
      })
    );
  }

  @Action(UpdateBudgets)
  UpdateBudgets(
    ctx: StateContext<BudgetPostStateModel>,
    { payload, id }: UpdateBudgetPost
  ) {
    return this.budgetPostService.updateBudgets(id, payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          budgetPosts: [...state.budgetPosts, data],
        });
      })
    );
  }

  @Action(CreateBudget)
  createBudget(
    ctx: StateContext<BudgetPostStateModel>,
    { payload, id }: CreateBudget
  ) {
    return this.budgetPostService.createBudget(id, payload).pipe(
      tap((data) => {
        const state = ctx.getState();
        ctx.patchState({
          budgetPosts: [...state.budgetPosts, data],
        });
      })
    );
  }

  @Action(ArchiveBudgetPostById)
  archiveBudgetPostById(
    ctx: StateContext<BudgetPostStateModel>,
    { payload, id }: ArchiveBudgetPostById
  ) {
    return this.budgetPostService.archiveBudgetPost(payload, id).pipe(
      tap((data) => {
        ctx.patchState({
          budgetPosts: data,
        });
      })
    );
  }
}
