<div class="table-wrapper">
  <div style="display: flex;">
    <app-status-filter [selectedStatus]="selectedStatus" (onFilterChange)="onFilterChange($event)"></app-status-filter>
    <div class="fied" style="margin-left: 2rem">
      <label class="label">Periode</label>
      <mat-form-field>
        <mat-label>Selecteer een periode</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Startdatum">
          <input matEndDate formControlName="end" placeholder="Einddatum">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <button class="clearbutton" *ngIf="range.get('start')?.value && range.get('end')?.value" (click)="clearDateRange()">
        <i class="las la-times"></i>
      </button>
    </div>
  </div>
  <table *ngIf="sortedData.length > 0; else noData" class="table clickable" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr>
        <th mat-sort-header="code">Code</th>
        <th mat-sort-header="status">Status</th>
        <th mat-sort-header="amount">Bedrag</th>
        <th>Budgetpost</th>
        <th mat-sort-header="user">Gebruiker</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let element of sortedData"
        (click)="closeRefDialog()"
        [routerLink]="['/orders/' + element.id]"
      >
        <td>{{ element.code }}</td>
        <td>
          <progress *ngIf="element.archived; else statusBar" class="progress is-small is-warning" [value]="4"
            #tooltip="matTooltip" matTooltip="Gearchiveerd" matTooltipPosition="above" [max]="4"></progress>

          <ng-template #statusBar>
            <app-status-bar [approved]="element.approved" [value]="calculateStatus(element.statuses)"
              [maxValue]="4"></app-status-bar>
          </ng-template>
        </td>
        <td>{{ getTotalCost(element.products) | currency : "EUR" }}</td>
        <td>{{ element.products[0].budget_post?.title }}</td>
        <td>
          {{ element.creator.first_name }} {{ element.creator.last_name }}
        </td>
        <td width="1" (click)="$event.stopPropagation()">
          <button class="button is-small" [matMenuTriggerFor]="menu">
            <span class="icon is-small">
              <i class="las la-ellipsis-v"></i>
            </span>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="!element.approved"
              mat-menu-item
              (click)="closeRefDialog()"
              [routerLink]="['/orders/create/' + element.id]"
            >
              Bewerken
            </button>
            <button *ngIf="!element.approved" mat-menu-item (click)="openDeleteDialog(element.id)">
              Verwijderen
            </button>
            <button *ngIf="element.approved" mat-menu-item (click)="generateOrderPDF(element.id)">
              PDF downloaden
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
  <ng-template #noData>
    <app-no-data title="Geen bestelbonnen gevonden."
      description="Er zijn geen bestelbonnen gevonden voor het geselecteerde budgetjaar. Kijk het geselecteerde budgetjaar na of druk op de knop om een nieuwe bestelbon aan te maken.">
      <button class="button is-info" [routerLink]="['/orders/create']">
        <span class="icon">
          <i class="las la-plus"></i>
        </span>
        <span>Bestelbon aanmaken</span>
      </button>
    </app-no-data>
  </ng-template>
</div>
