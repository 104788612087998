import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { BudgetPost } from 'src/app/models/budgetPost.interface';
import { User } from 'src/app/models/user.interface';
import { BudgetPostService } from 'src/app/services/budgetpost.service';
import {
  CreateBudgetPost,
  GetBudgetPosts,
  UpdateBudgetPost,
} from 'src/app/store/actions/budget-post.action';
import { BudgetPostState } from 'src/app/store/states/budget-post.state';

@Component({
  selector: 'app-edit-budgetpost',
  templateUrl: './edit-budgetpost.component.html',
  styleUrls: ['./edit-budgetpost.component.scss'],
})
export class EditBudgetPostComponent implements OnInit {
  editForm: FormGroup;
  loading: boolean = false;
  error: string = '';
  title: string = 'Budgetpost';
  budgetposts: BudgetPost[] = [];
  budgetHolders: any[] = [];
  edit: boolean;

  @Select(BudgetPostState.budgetPosts)
  budgetPosts$: Observable<BudgetPost[]>;

  constructor(
    private budgetPostService: BudgetPostService,
    private store: Store,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditBudgetPostComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store.dispatch(new GetBudgetPosts());
  }

  ngOnInit(): void {
    if (this.data?.id) {
      this.title = 'Budgetpost bewerken';
      this.getData(this.data.id);
      this.edit = true;
    } else {
      this.title = 'Budgetpost aanmaken';
      this.budgetPostService
        .getBudgetPostHolders()
        .subscribe((data: User[]) => {
          this.budgetHolders = data;
        });

      this.initForm(null);
      this.edit = false;
    }
  }

  getData(id: number) {
    const observable = forkJoin([
      this.budgetPostService.getBudgetPostById(id),
      this.budgetPostService.getBudgetPostHolders(),
    ]);
    observable.subscribe(([post, holders]) => {
      this.budgetHolders = [...post.budget_holders, ...holders];
      // Remove duplicate ID's from array
      this.budgetHolders = this.budgetHolders.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.initForm(post);
    });
  }

  initForm(budgetPost: BudgetPost | null) {
    let budgetHolderIDs = budgetPost?.budget_holders.map((user: User) => {
      return user.id;
    });

    this.editForm = this.fb.group({
      title: [
        budgetPost?.title || '',
        [Validators.required, Validators.maxLength(255)],
      ],
      budget_holders: [budgetHolderIDs || [], Validators.required],
    });
  }

  saveChanges(budgetPost: any) {
    this.loading = true;
    // Check if add or edit
    if (this.edit) {
      this.store
        .dispatch(new UpdateBudgetPost(budgetPost, this.data.id))
        .subscribe(
          (data) => {
            this.loading = false;
            this.toastr.success('Budgetpost is gewijzigd!');
            this.dialogRef.close(true);
          },
          (error) => {
            this.error = error.error.message;
            this.toastr.error(error.error, 'Er is iets misgegaan!');
            this.loading = false;
          }
        );
    } else {
      this.store.dispatch(new CreateBudgetPost(budgetPost)).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Budgetpost is toegevoegd!');
          this.dialogRef.close(true);
        },
        (error) => {
          this.error = error.error.message;
          this.loading = false;
        }
      );
    }
  }

  get f() {
    return this.editForm.controls;
  }
}
