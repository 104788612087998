import { FestivalForm } from 'src/app/models/festival.interface';

export class GetFestivals {
  static readonly type = '[Festivals] Get festivals';
}

export class GetFestivalById {
  static readonly type = '[Festivals] Get festival';
  constructor(public id: number) {}
}

export class CreateFestival {
  static readonly type = '[Festivals] Create festival';
  constructor(public payload: FestivalForm) {}
}

export class UpdateFestival {
  static readonly type = '[Festivals] Update festival';
  constructor(public payload: FestivalForm, public id: number) {}
}
