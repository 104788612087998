<div class="app__main__title">
  <div>

    <nav class="breadcrumb" aria-label="breadcrumbs" *ngIf="breadCrumbs">
      <ul>
        <li class="breadcrumb-item" *ngFor="let item of breadCrumbs">
          <a *ngIf="item.route" [routerLink]="['/' + item.route]">{{item.name}}</a>
          <a *ngIf="!item.route" href="javascript:void(0)">{{item.name}}</a>
        </li>
      </ul>
    </nav>


    <h1>{{title}}</h1>
  </div>

  <div>
    <ng-content></ng-content>
  </div>
</div>