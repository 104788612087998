import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APPROVAL_TYPES } from 'src/app/models/ApprovalType.enum';
import { InvoiceStatus } from 'src/app/models/invoiceStatus.interface';
import { Order } from 'src/app/models/order.interface';
import { ROLES } from 'src/app/models/roles.enum';
import { AuthService } from 'src/app/services/auth.service';
import { EvaluateInvoiceModalComponent } from '../evaluate-invoice-modal/evaluate-invoice-modal.component';

@Component({
  selector: 'app-evaluate-invoice',
  templateUrl: './evaluate-invoice.component.html',
  styleUrls: ['./evaluate-invoice.component.scss'],
})
export class EvaluateInvoiceComponent implements OnInit {
  @Input() order: Order;
  @Output() refresh = new EventEmitter<boolean>();
  user;
  evaluate: boolean;
  revaluate: boolean;
  hidden: boolean;

  invoiceStatusId: number | null;

  constructor(public dialog: MatDialog, private authService: AuthService) {
    this.user = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.checkStatusAndRoles();
  }

  checkStatusAndRoles() {
    this.hidden = true;
    const orderStatusArray = this.order.invoice_statuses.filter(
      (status: any) => status.order_id == this.order.id
    );

    const userStatus = orderStatusArray.find(
      (status: InvoiceStatus) => status.approver_id === this.user.id
    );

    if (userStatus) {
      this.hidden = false;
      this.invoiceStatusId = userStatus.id;

      if (userStatus.approved) {
        this.evaluate = false;
        this.revaluate = true;
      } else {
        this.revaluate = false;
        this.evaluate = true;
      }
    } else if (this.user.role.id === ROLES.FINANCIALMAINTAINER) {
      let previousOrder = orderStatusArray.find(
        (status: InvoiceStatus) => status.approver_id !== null
      );

      // Check if order has been approved, if not, this role cannot evaluate yet
      if (
        previousOrder &&
        previousOrder?.approved === APPROVAL_TYPES.APPROVED
      ) {
        this.hidden = false;
        // Get status for currentRole
        let nextStatus = orderStatusArray.find(
          (status: InvoiceStatus) =>
            status.role.id === ROLES.FINANCIALMAINTAINER
        );

        this.invoiceStatusId = nextStatus?.id || null;

        if (nextStatus && nextStatus.approved) {
          this.evaluate = false;
          this.revaluate = true;
        } else {
          this.revaluate = false;
          this.evaluate = true;
        }
      }
    }

    // // Check allowed user roles
    // switch (this.user.role.id) {
    //   case ROLES.BUDGETHOLDER: {
    //     // Check if the budgetholder's budgetpost is in the order's budgetpost
    //     if (
    //       this.user.budget_posts.some(
    //         (bp: BudgetPost) => bp.id === this.order.products[0].budget_post.id
    //       )
    //     ) {
    //       // User is allowed to evaluate
    //       this.hidden = false;
    //       // Get the status of the order for a budgetholder
    //       let budgetOrderStatus = orderStatusArray.find(
    //         (status: any) => status.role.id === this.user.role.id
    //       );

    //       // Check if order has been evaluated by other budgetholder
    //       if (budgetOrderStatus && budgetOrderStatus.approved) {
    //         this.evaluate = false;
    //         this.revaluate = true;
    //       } else {
    //         this.revaluate = false;
    //         this.evaluate = true;
    //       }
    //     }
    //     break;
    //   }

    //   case ROLES.FINANCIALMAINTAINER: {
    //     this.evaluateOrder(
    //       orderStatusArray,
    //       ROLES.BUDGETHOLDER,
    //       ROLES.FINANCIALMAINTAINER
    //     );
    //     break;
    //   }

    //   case ROLES.DIRECTOR: {
    //     this.evaluateOrder(
    //       orderStatusArray,
    //       ROLES.FINANCIALMAINTAINER,
    //       ROLES.DIRECTOR
    //     );
    //     break;
    //   }
    //   default:
    //     this.hidden = true;
    //     break;
    // }
  }

  evaluateOrder(status: any[], roleIdPrevious: number, roleIdNext: number) {
    // check if previous role approved
    let orderStatus = status.find(
      (status: any) => status.role.id === roleIdPrevious
    );

    // Check if order has been approved by financieel beheerder, if not, this role cannot evaluate yet
    if (orderStatus && orderStatus?.approved === APPROVAL_TYPES.APPROVED) {
      this.hidden = false;
      // Get status for currentRole
      let nextStatus = status.find(
        (status: any) => status.role.id === roleIdNext
      );
      if (nextStatus && nextStatus.approved) {
        this.evaluate = false;
        this.revaluate = true;
      } else {
        this.revaluate = false;
        this.evaluate = true;
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EvaluateInvoiceModalComponent, {
      width: '500px',
      data: { orderId: this.order.id, invoiceStatusId: this.invoiceStatusId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.hidden = false;
        this.evaluate = false;
        this.revaluate = true;
        this.refresh.emit(true);
      }
    });
  }
}
