import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers() {
    return this.http.get<any>(environment.apiUrl + '/api/users');
  }

  getUserById(id: number) {
    return this.http.get<any>(environment.apiUrl + `/api/users/${id}`);
  }

  createUser(user: any) {
    return this.http.post<any>(environment.apiUrl + '/api/users', user);
  }

  updateUser(user: any, id: number) {
    return this.http.put<any>(environment.apiUrl + `/api/users/${id}`, user);
  }

  updatePassword(user: any) {
    return this.http.post<any>(
      environment.apiUrl + `/api/change-password`,
      user
    );
  }

  changeActiveUser(isActive: boolean, id: number) {
    return this.http.put<any>(
      environment.apiUrl + `/api/users/update-active/${id}`,
      {
        is_active: isActive,
      }
    );
  }
}
