import { ContractForm } from 'src/app/models/contract.interface';

export class GetContracts {
  static readonly type = '[Contracts] Get contracts';
}

export class GetContractById {
  static readonly type = '[Contracts] Get contract';
  constructor(public id: number) {}
}

export class CreateContract {
  static readonly type = '[Contracts] Create contract';
  constructor(public payload: ContractForm) {}
}

export class UpdateContract {
  static readonly type = '[Contracts] Update contract';
  constructor(public payload: ContractForm, public id: number) {}
}
