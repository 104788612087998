import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Product } from 'src/app/models/product.interface';
import { ProductService } from 'src/app/services/product.service';
import { GetProducts } from '../actions/product.action';

export class ProductStateModel {
  products: Product[];
  product: Product;
  isLoading: boolean;
  budgetYear: number;
}

@State<ProductStateModel>({
  name: 'Product',
  defaults: {
    products: [],
    product: {} as Product,
    isLoading: false,
    budgetYear: 0,
  },
})
@Injectable()
export class ProductState {
  constructor(private productService: ProductService) {}

  @Selector()
  static productOptions(state: ProductStateModel): string[] {
    return [...new Set(state.products.map((product: Product) => product.name))];
  }

  @Selector()
  static isLoading(state: ProductStateModel): boolean {
    return state.isLoading;
  }

  @Action(GetProducts)
  getProducts(ctx: StateContext<ProductStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    return this.productService.getProducts().pipe(
      tap((data) => {
        ctx.patchState({
          isLoading: false,
          products: data,
        });
      })
    );
  }
}
