import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Supplier, SupplierForm } from '../models/supplier.interface';

const URL = '/api/suppliers';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  constructor(private http: HttpClient) {}

  getSuppliers() {
    return this.http.get<Supplier[]>(environment.apiUrl + URL);
  }

  getSupplier(id: number) {
    return this.http.get<Supplier>(environment.apiUrl + `${URL}/${id}`);
  }

  getSuppliersCheckList() {
    return this.http.get<Supplier[]>(environment.apiUrl + `${URL}/check`);
  }

  getSupplierById(id: number) {
    return this.http.get<Supplier>(environment.apiUrl + `${URL}/check/${id}`);
  }

  createSupplier(supplier: SupplierForm) {
    return this.http.post<any>(environment.apiUrl + `${URL}/check`, supplier);
  }

  deleteSupplier(id: number) {
    return this.http.delete<any>(environment.apiUrl + `${URL}/check/${id}`);
  }

  updateSupplier(supplier: SupplierForm, id: number) {
    return this.http.put<any>(
      environment.apiUrl + `${URL}/check/${id}`,
      supplier
    );
  }
}
