<div mat-dialog-content>
  <h1>{{ message }}</h1>
</div>
<div mat-dialog-actions style="justify-content: space-between">
  <button class="button is-light" mat-dialog-close cdkFocusInitial>
    Annuleren
  </button>
  <button class="button is-info" [mat-dialog-close]="true" (click)="confirm()">
    Bevestigen
  </button>
</div>
