<div class="fied" style="margin-bottom: 0.75rem;">
  <label class="label" *ngIf="label">Budgetjaar <span *ngIf="required">*</span></label>
  <div class="control has-icons-left">
    <div class="select is-fullwidth">
      <select [(ngModel)]="input" (ngModelChange)="onChange($event)" (blur)="onTouch()" [disabled]="isDisabled()">
        <option *ngFor="let item of budgetYears$ | async" [value]="item.id">{{item.name}}</option>
      </select>
    </div>
    <div class="icon is-small is-left">
      <i class="las la-calendar"></i>
    </div>
  </div>
</div>
