export class GetUsers {
  static readonly type = '[Users] Get users';
}

export class GetUserById {
  static readonly type = '[Users] Get user';
  constructor(public id: number) {}
}

export class CreateUser {
  static readonly type = '[Users] Create user';
  constructor(public payload: any) {}
}

export class UpdateUser {
  static readonly type = '[Users] Update user';
  constructor(public payload: any, public id: number) {}
}

export class ChangeActiveUser {
  static readonly type = '[Users] Update user is_active';
  constructor(public payload: any, public id: number) {}
}
