import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss'],
})
export class StatusFilterComponent implements OnInit {
  @Input()
  statuses: { name: string; value: boolean }[] = [
    { name: 'Actief', value: false },
    { name: 'Inactief', value: true },
  ];

  @Input()
  selectedStatus = false;

  @Output()
  onFilterChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  change(event: boolean) {
    this.onFilterChange.emit(event);
  }
}
