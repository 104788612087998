import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './helpers/token.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { BudgetPostState } from './store/states/budget-post.state';
import { BudgetYearState } from './store/states/budget-year.state';
import { ContractState } from './store/states/contract.state';
import { FestivalState } from './store/states/festival.state';
import { OrderState } from './store/states/order.state';
import { ProductState } from './store/states/product.state';
import { RoleState } from './store/states/role.state';
import { SupplierState } from './store/states/supplier.state';
import { UserState } from './store/states/user.state';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';



const STATES = [
  BudgetYearState,
  OrderState,
  RoleState,
  UserState,
  BudgetPostState,
  ProductState,
  ContractState,
  FestivalState,
  SupplierState,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SharedModule,
    NgxsModule.forRoot(STATES, {
      developmentMode: !environment.production,

    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
