export class GetBudgetYears {
  static readonly type = '[BudgetYears] Get budgetyears';
}

export class GetBudgetYearById {
  static readonly type = '[BudgetYears] Get budgetyear';
  constructor(public id: number) {}
}

export class CreateBudgetYear {
  static readonly type = '[BudgetYears] Create budgetyear';
  constructor(public payload: any) {}
}

export class UpdateBudgetYear {
  static readonly type = '[BudgetYears] Update budgetyear';
  constructor(public payload: any, public id: number) {}
}

export class ArchiveBudgetYearById {
  static readonly type = '[BudgetYears] Archive budgetYear by id';
  constructor(public payload: boolean, public id: number) {}
}
