import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnInit, OnChanges {
  @Input() value: number = 0;
  @Input() maxValue: number = 100;
  @Input() approved: boolean = false;
  tooltipText: string = '';

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.tooltipText = 'Dit is mijn text' + this.value;

    switch (this.value) {
      case 1:
        this.tooltipText =
          'De bestelbon is ingediend. Stap 1/4. De budgethouder moet het controleren.';
        break;
      case 2:
        this.tooltipText =
          'De bestelbon is goedgekeurd door de budgethouder. Stap 2/4. De financiële beheerder moet het controleren.';
        break;
      case 3:
        this.tooltipText =
          'De bestelbon is goedgekeurd door de financieel beheerder. Stap 3/4. De Directeur moet het controleren.';
        break;
      case 4:
        this.tooltipText = 'De bestelbon is goedgekeurd.';
        break;
      default:
        this.tooltipText = 'De status van de bestelbon is onbekend';
        break;
    }
  }
}
