export class GetOrders {
  static readonly type = '[Orders] Get orders';
}

export class GetOrderById {
  static readonly type = '[Orders] Get order';
  constructor(public id: number) {}
}

export class CreateOrder {
  static readonly type = '[Orders] Create order';
  constructor(public payload: any) {}
}

export class UpdateOrder {
  static readonly type = '[Orders] Update order';
  constructor(public payload: any, public id: number) {}
}

export class DeleteOrderById {
  static readonly type = '[Orders] Delete order';
  constructor(public id: number) {}
}

export class SetBudgetYearOrder {
  static readonly type = '[Order] Set budgetyear';
  constructor(public id: number) {}
}
